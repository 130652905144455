.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.powerbi-container{
  display: flex;
  height: 90vh;
  justify-content: center;
  align-items: center;
}

.Loading-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: black;
  background-size: cover;
  cursor: pointer;
}

.markerPlayBack {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: red;
  background-size: cover;
  cursor: pointer;
}

.marker-popup {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: black;
  background-size: cover;
  cursor: pointer;
  display: flex;
  align-items: left;
  margin-right: 5px;
  padding: 0;
}

.marker-black {
  background-color: #333333;
}

.marker-selected {
  background-color: #001489;
}

.marker-unassignedOnTime {
  background-color: #347f51;
}

.marker-unassignedWarning {
  background-color: #e49600;
}

.marker-unassignedDelayed {
  background-color: #ec161f;
}

.mapbox-response::-webkit-scrollbar {
  display: none;
}

.invoice-button {
  display: flex;
  align-items: left;
  justify-content: flex-start;
  border-radius: 6px;
  padding-left: 16px !important;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  position: relative;
  /* Add relative positioning */
  margin-bottom: 4px;
  line-height: 13px;
}

.invoice-button::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 0.5px;
  background-color: #e5e7f3;
  /* Line color */
}

.invoice-button:last-child::after {
  height: 0;
  /* Remove the line for the last invoice button */
}

.highlighted {
  background-color: #e5e7f3;
}

.invoice-button:hover {
  background-color: #e5e7f3;
}

.custom-popup {
  border-radius: 6px;
  padding: 0;
  margin: 0;
  margin-bottom: 0;
  box-shadow: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.custom-popup.no-anchor .mapboxgl-popup-tip {
  display: none;
}

.custom-popup.rounded-bottom-right {
  border-bottom-right-radius: 6px;
}

.custom-popup h4 {
  text-align: left;
  margin: 0;
  padding: 8px 12px;
  border-bottom: 1px solid #e5e7f3;
  margin-bottom: 4px;
  justify-content: flex-start;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: bold;
}

.phone-number {
  display: block;
  justify-content: flex-start;
  font-weight: lighter;
}

.custom-popup ul {
  text-align: left;
  margin-bottom: 4px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  scrollbar-width: thin !important;
  scrollbar-color: transparent transparent !important;
  max-height: 200px;
}

.custom-popup ul::-webkit-scrollbar {
  width: 4px;
}

.custom-popup ul::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.custom-popup ul::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 4px;
}

.custom-popup ul:hover::-webkit-scrollbar-thumb {
  background-color: #001489;
}

.custom-popup ul:hover::-webkit-scrollbar-track {
  background-color: #e5e7f3;
}

.custom-popup li {
  margin: 0;
  padding: 8px;
  margin-bottom: 4px;
  text-align: left;
}

.custom-popup li:not(:last-child) {
  margin-bottom: 8px;
  /* Add spacing between invoice buttons */
}

.custom-popup li:last-child {
  border-bottom: none;
}

.info-box {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(38, 37, 37, 0.7);
  color: white;
  padding: 5px;
  font-family: 'Napa Sans';
  font-size: 12px;
  opacity: 0.5;
}

/*#region Inspection-Report*/
@media print {
  div[aria-label="inspection-report-container"]{
      max-height: none !important;
      overflow: visible !important;
      border: none !important;
      box-shadow: none !important;
  }

  p[aria-label="inspection-report-print-lbl"],
  div[aria-label="inspection-report-print-icon"],
  button[aria-label="inspection-report-close-btn"],
  button[data-testid="close_button"] {
      display: none;
  }

  @page {
      margin: 20mm;
  }
}

.inspection-report::-webkit-scrollbar {
  width: 10px;
}

.inspection-report::-webkit-scrollbar-thumb {
  background: transparent; 
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.inspection-report:hover::-webkit-scrollbar-thumb {
  background-color: #cdcaca;
}
/*#endregion*/

div[title="Delayed"] { 
  color: #A0171E
}
